export const state = () => ({
  marketing: false,
  necessary: true,
  preferences: false,
  statistics: false,
  consented: false,
  declined: false,
  show: false,
})

export const mutations = {
  update(state, status) {
    Object.keys(status).forEach((key) => {
      state[key] = status[key]
    })
  },
  show(state) {
    state.show = true
  },
  hide(state) {
    state.show = false
  },
}
