// Ref: https://nuxtjs.org/docs/2.x/configuration-glossary/configuration-router#scrollbehavior
// Ref: https://dev.to/dimer191996/nuxt-js-smooth-scrolling-with-hash-links-94a
export default async function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }

  const findEl = (hash, x) => {
    return (
      document.querySelector(hash) ||
      new Promise((resolve, reject) => {
        if (x > 50) {
          return resolve()
        }
        setTimeout(async () => {
          resolve(await findEl(hash, ++x || 1))
        }, 100)
      })
    )
  }

  if (to.hash) {
    const el = await findEl(to.hash)
    if ('scrollBehavior' in document.documentElement.style) {
      return window.scrollTo({ top: el.offsetTop - 110, behavior: 'smooth' })
    } else {
      return window.scrollTo(0, el.offsetTop)
    }
  }

  if ('scrollBehavior' in document.documentElement.style) {
    return window.scrollTo({ top: 0, behavior: 'smooth' })
  } else {
    return window.scrollTo(0, 0)
  }
}
