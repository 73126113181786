import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=2a866ab9&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=postcss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavLink: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/content/utilities/NavLink.vue').default,TYPO3MenuAccordion: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/content/utilities/TYPO3MenuAccordion.js').default})
