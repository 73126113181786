import { render, staticRenderFns } from "./error.vue?vue&type=template&id=430ca366&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BeDynamic: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/node_modules/nuxt-typo3/lib/templates/layouts/backend/BeDynamic.js').default})
