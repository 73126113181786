import { render, staticRenderFns } from "./Mainframe.vue?vue&type=template&id=73d39e06&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThemeOrganismsNavigation: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/theme/organisms/Navigation.vue').default,ThemeOrganismsFooter: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/theme/organisms/Footer.vue').default})
