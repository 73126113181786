import Vue from 'vue'
import BeMain from '~/components/backend/BeMain'
import BeTwoColumn from '~/components/backend/BeTwoColumn'

const registerBackendLayouts = {
  install: (Vue, options) => {
    const layouts = options.layouts

    Object.keys(layouts).forEach((key) => {
      Vue.component(key, layouts[key])
      options.context.components[key] = layouts[key] // pass layout to application context for SSR purspoes
    })
  },
}

const layouts = {
  BeMain,
  BeDefault: BeMain,
  BeTwoColumn,
}

export { registerBackendLayouts }

export default ({ app }) => {
  Vue.use(registerBackendLayouts, {
    context: app,
    layouts,
  })
}
