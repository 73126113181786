//
//
//
//
//
//
//
//

import Icons from '~/assets/sprite/Icons.svg?inline'

export default {
  name: 'LayoutDefault',
  components: {
    Icons,
  },
}
