import { render, staticRenderFns } from "./default.vue?vue&type=template&id=de6afdcc&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThemeTemplatesMainframe: require('/home/rof/src/bitbucket.org/medienagenten/krauss-der-stein-nuxtjs/components/theme/templates/Mainframe.vue').default})
