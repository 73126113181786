import Vue from 'vue'

const filter = (text) => {
  return text.split(/\r\n|\n\r|\r|\n/g).reduce((accumulator, string) => {
    if (!Array.isArray(accumulator)) {
      return accumulator + '<br/>' + string
    }
    return accumulator.concat(['<br/>', string])
  })
}

Vue.filter('NL2BR', filter)
