// https://stackoverflow.com/questions/70896121/how-to-create-a-copy-of-the-slot-with-createelement
export default {
  props: {
    children: {
      type: Array,
      required: true,
      default: () => [],
    },
    level: {
      type: Number,
      required: false,
      default: 1,
    },
    showOnlyOne: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      active: [],
    }
  },
  methods: {
    beforeEnter(el) {
      this.$gsap.set(el, { opacity: 0 })
    },
    enter(el, done) {
      this.$gsap
        .timeline()
        .fromTo(
          el,
          { height: 0 },
          {
            height: el.scrollHeight,
            duration: 0.3,
            ease: 'Power1.easeInOut',
          }
        )
        .to(el, {
          opacity: 1,
          duration: 0.2,
          ease: 'Power1.easeInOut',
          onComplete: done,
        })
    },
    afterEnter(el) {
      this.$gsap.set(el, {
        clearProps: 'height',
      })
    },
    leave(el, done) {
      this.$gsap.fromTo(
        el,
        { height: el.scrollHeight },
        {
          height: 0,
          opacity: 0,
          duration: 0.3,
          ease: 'Power1.easeInOut',
          onComplete: done,
        }
      )
    },
    afterLeave(el) {
      this.$gsap.set(el, {
        clearProps: 'height',
      })
    },
    item(createElement, { item, key }) {
      let slot = null
      if (this.$scopedSlots.item) slot = 'item'
      if (this.$scopedSlots[`item-l${this.level}`]) slot = `item-l${this.level}`

      if (slot) {
        return this.$scopedSlots[slot]({
          item,
          click: () => {
            this.click(key)
          },
        })
      } else {
        return createElement(
          'NavLink',
          {
            props: {
              to: item.link,
            },
            attrs: {
              target: item.target || false,
              title: item.title,
            },
          },
          item.title
        )
      }
    },
    click(key) {
      const index = this.active.indexOf(key)

      switch (this.showOnlyOne) {
        case true:
          if (index > -1) {
            this.active = []
          } else {
            this.active = [key]
          }
          break

        default:
          if (index > -1) {
            this.active.splice(index, 1)
          } else {
            this.active.push(key)
          }
          break
      }
    },
  },
  render(createElement) {
    return createElement(
      'ul',
      {
        class: {
          'overflow-hidden': true,
        },
      },
      // Die Menüpunkte iterieren.
      this.children.map((el) => {
        const key = this.children.indexOf(el)

        return createElement('li', [
          // Prüfen ob der slot Item verwendet wird & falls nicht den fallback ausgeben.
          this.item(createElement, { item: el, key }),
          createElement(
            'transition',
            {
              on: {
                beforeEnter: this.beforeEnter,
                enter: this.enter,
                afterEnter: this.afterEnter,
                leave: this.leave,
                afterleave: this.afterLeave,
              },
            },
            [
              el.children && this.active.includes(key)
                ? createElement('TYPO3MenuAccordion', {
                    props: {
                      children: el.children,
                      level: this.level + 1,
                    },
                    scopedSlots: this.$scopedSlots,
                  })
                : false,
            ]
          ),
        ])
      })
    )
  },
}
