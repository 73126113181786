// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n.footer_vtO4y{\n  --tw-bg-opacity:1;\n  background-color:rgba(0, 0, 0, var(--tw-bg-opacity))\n}\n.inner_RDLII{\n  display:grid;\n  grid-template-columns:repeat(1, minmax(0, 1fr));\n  gap:2rem;\n  --tw-text-opacity:1;\n  color:rgba(255, 255, 255, var(--tw-text-opacity))\n}\n@media (min-width: 768px){\n.inner_RDLII{\n    grid-template-columns:repeat(2, minmax(0, 1fr))\n}\n}\n@media (min-width: 1024px){\n.inner_RDLII{\n    grid-template-columns:repeat(4, minmax(0, 1fr))\n}\n}\n.inner_RDLII{\n  margin-left:auto;\n  margin-right:auto;\n  max-width:80rem;\n  padding:2rem;\n  padding-bottom:4rem\n}\n.link_Y9iPi{\n  cursor:pointer;\n  font-size:1rem;\n  line-height:1em\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer_vtO4y",
	"inner": "inner_RDLII",
	"link": "link_Y9iPi"
};
module.exports = ___CSS_LOADER_EXPORT___;
