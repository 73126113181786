export const state = () => ({
  menuStatus: false,
})

export const mutations = {
  closeMenu(state) {
    state.menuStatus = false
  },
  openMenu(state) {
    state.menuStatus = false
  },
  triggerMenu(state) {
    state.menuStatus = !state.menuStatus
  },
}
