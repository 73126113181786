export default function ({ store }, inject) {
  const update = () => {
    const { Cookiebot } = window
    const { consented, consent, declined } = Cookiebot
    const { marketing, necessary, preferences, statistics } = consent

    store.commit('cookiebot/update', {
      marketing,
      necessary,
      preferences,
      statistics,
      consented,
      declined,
    })
  }

  window.addEventListener('CookiebotOnDialogInit', update, false)
  window.addEventListener('CookiebotOnConsentReady', update, false)
  window.addEventListener('CookiebotOnAccept', update, false)
  window.addEventListener('CookiebotOnDecline', update, false)
  window.addEventListener('CookiebotOnLoad', update, false)
  window.addEventListener('CookiebotOnDialogDisplay', update, false)
}
