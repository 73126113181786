export const MediaFile = () => import('../../node_modules/nuxt-typo3/lib/templates/components/content/elements/media/File.vue' /* webpackChunkName: "components/media-file" */).then(c => wrapFunctional(c.default || c))
export const MediaGallery = () => import('../../node_modules/nuxt-typo3/lib/templates/components/content/elements/media/Gallery.vue' /* webpackChunkName: "components/media-gallery" */).then(c => wrapFunctional(c.default || c))
export const CeDefault = () => import('../../node_modules/nuxt-typo3/lib/templates/components/content/elements/CeDefault.js' /* webpackChunkName: "components/ce-default" */).then(c => wrapFunctional(c.default || c))
export const BeDefault = () => import('../../components/backend/BeDefault.vue' /* webpackChunkName: "components/be-default" */).then(c => wrapFunctional(c.default || c))
export const BeDynamic = () => import('../../node_modules/nuxt-typo3/lib/templates/layouts/backend/BeDynamic.js' /* webpackChunkName: "components/be-dynamic" */).then(c => wrapFunctional(c.default || c))
export const HtmlParser = () => import('../../components/content/utilities/HtmlParser.vue' /* webpackChunkName: "components/html-parser" */).then(c => wrapFunctional(c.default || c))
export const LangSwitcher = () => import('../../node_modules/nuxt-typo3/lib/templates/components/utilities/LangSwitcher.vue' /* webpackChunkName: "components/lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const NavLink = () => import('../../components/content/utilities/NavLink.vue' /* webpackChunkName: "components/nav-link" */).then(c => wrapFunctional(c.default || c))
export const CeDebug = () => import('../../node_modules/nuxt-typo3/lib/templates/components/content/CeDebug.js' /* webpackChunkName: "components/ce-debug" */).then(c => wrapFunctional(c.default || c))
export const CeDynamic = () => import('../../node_modules/nuxt-typo3/lib/templates/components/content/CeDynamic.js' /* webpackChunkName: "components/ce-dynamic" */).then(c => wrapFunctional(c.default || c))
export const CeFrame = () => import('../../components/content/elements/CeFrame.vue' /* webpackChunkName: "components/ce-frame" */).then(c => wrapFunctional(c.default || c))
export const CeRenderer = () => import('../../components/content/elements/CeRenderer.js' /* webpackChunkName: "components/ce-renderer" */).then(c => wrapFunctional(c.default || c))
export const CeContactsContact = () => import('../../components/content/elements/CeContactsContact.vue' /* webpackChunkName: "components/ce-contacts-contact" */).then(c => wrapFunctional(c.default || c))
export const CeContactsOpening = () => import('../../components/content/elements/CeContactsOpening.vue' /* webpackChunkName: "components/ce-contacts-opening" */).then(c => wrapFunctional(c.default || c))
export const CeContactsPerson = () => import('../../components/content/elements/CeContactsPerson.vue' /* webpackChunkName: "components/ce-contacts-person" */).then(c => wrapFunctional(c.default || c))
export const CeFormFormframework = () => import('../../components/content/elements/CeForm_formframework.vue' /* webpackChunkName: "components/ce-form-formframework" */).then(c => wrapFunctional(c.default || c))
export const CeHeader = () => import('../../components/content/elements/CeHeader.vue' /* webpackChunkName: "components/ce-header" */).then(c => wrapFunctional(c.default || c))
export const CeHeadlessSitepackageGallery = () => import('../../components/content/elements/CeHeadlessSitepackageGallery.vue' /* webpackChunkName: "components/ce-headless-sitepackage-gallery" */).then(c => wrapFunctional(c.default || c))
export const CeHeadlessSitepackageHero = () => import('../../components/content/elements/CeHeadlessSitepackageHero.vue' /* webpackChunkName: "components/ce-headless-sitepackage-hero" */).then(c => wrapFunctional(c.default || c))
export const CeHeadlessSitepackageTextimage = () => import('../../components/content/elements/CeHeadlessSitepackageTextimage.vue' /* webpackChunkName: "components/ce-headless-sitepackage-textimage" */).then(c => wrapFunctional(c.default || c))
export const CeImage = () => import('../../components/content/elements/CeImage.vue' /* webpackChunkName: "components/ce-image" */).then(c => wrapFunctional(c.default || c))
export const CeMediaFile = () => import('../../components/content/elements/CeMediaFile.vue' /* webpackChunkName: "components/ce-media-file" */).then(c => wrapFunctional(c.default || c))
export const CeNewsPi1 = () => import('../../components/content/elements/CeNews_pi1.vue' /* webpackChunkName: "components/ce-news-pi1" */).then(c => wrapFunctional(c.default || c))
export const CeText = () => import('../../components/content/elements/CeText.vue' /* webpackChunkName: "components/ce-text" */).then(c => wrapFunctional(c.default || c))
export const CeForms = () => import('../../components/content/plugins/forms/index.vue' /* webpackChunkName: "components/ce-forms" */).then(c => wrapFunctional(c.default || c))
export const CeFormsModuleBar = () => import('../../components/content/plugins/forms/Module/Bar.vue' /* webpackChunkName: "components/ce-forms-module-bar" */).then(c => wrapFunctional(c.default || c))
export const CeFormsModuleControll = () => import('../../components/content/plugins/forms/Module/Controll.vue' /* webpackChunkName: "components/ce-forms-module-controll" */).then(c => wrapFunctional(c.default || c))
export const CeFormsModuleForm = () => import('../../components/content/plugins/forms/Module/Form.vue' /* webpackChunkName: "components/ce-forms-module-form" */).then(c => wrapFunctional(c.default || c))
export const CeFormsModulePage = () => import('../../components/content/plugins/forms/Module/Page.vue' /* webpackChunkName: "components/ce-forms-module-page" */).then(c => wrapFunctional(c.default || c))
export const CeFormsModuleRenderer = () => import('../../components/content/plugins/forms/Module/Renderer.vue' /* webpackChunkName: "components/ce-forms-module-renderer" */).then(c => wrapFunctional(c.default || c))
export const CeNewsDetailJobs = () => import('../../components/content/plugins/news/detail/DetailJobs.vue' /* webpackChunkName: "components/ce-news-detail-jobs" */).then(c => wrapFunctional(c.default || c))
export const CeNewsDetailNews = () => import('../../components/content/plugins/news/detail/DetailNews.vue' /* webpackChunkName: "components/ce-news-detail-news" */).then(c => wrapFunctional(c.default || c))
export const CeNewsListJobs = () => import('../../components/content/plugins/news/list/ListJobs.vue' /* webpackChunkName: "components/ce-news-list-jobs" */).then(c => wrapFunctional(c.default || c))
export const CeNewsListNews = () => import('../../components/content/plugins/news/list/ListNews.vue' /* webpackChunkName: "components/ce-news-list-news" */).then(c => wrapFunctional(c.default || c))
export const CeNewsTeaserNews = () => import('../../components/content/plugins/news/teaser/TeaserNews.vue' /* webpackChunkName: "components/ce-news-teaser-news" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/content/utilities/Accordion.js' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const BackgroundImage = () => import('../../components/content/utilities/BackgroundImage.vue' /* webpackChunkName: "components/background-image" */).then(c => wrapFunctional(c.default || c))
export const BackgroundVideo = () => import('../../components/content/utilities/BackgroundVideo.vue' /* webpackChunkName: "components/background-video" */).then(c => wrapFunctional(c.default || c))
export const Masonry = () => import('../../components/content/utilities/Masonry.vue' /* webpackChunkName: "components/masonry" */).then(c => wrapFunctional(c.default || c))
export const MasonryItem = () => import('../../components/content/utilities/MasonryItem.vue' /* webpackChunkName: "components/masonry-item" */).then(c => wrapFunctional(c.default || c))
export const NewsMeta = () => import('../../components/content/utilities/NewsMeta.js' /* webpackChunkName: "components/news-meta" */).then(c => wrapFunctional(c.default || c))
export const TYPO3MenuAccordion = () => import('../../components/content/utilities/TYPO3MenuAccordion.js' /* webpackChunkName: "components/t-y-p-o3-menu-accordion" */).then(c => wrapFunctional(c.default || c))
export const BeMain = () => import('../../components/backend/BeMain.vue' /* webpackChunkName: "components/be-main" */).then(c => wrapFunctional(c.default || c))
export const BeTwoColumn = () => import('../../components/backend/BeTwoColumn.vue' /* webpackChunkName: "components/be-two-column" */).then(c => wrapFunctional(c.default || c))
export const ThemeAtomsButton = () => import('../../components/theme/atoms/Button.vue' /* webpackChunkName: "components/theme-atoms-button" */).then(c => wrapFunctional(c.default || c))
export const ThemeAtomsCard = () => import('../../components/theme/atoms/Card.vue' /* webpackChunkName: "components/theme-atoms-card" */).then(c => wrapFunctional(c.default || c))
export const ThemeAtomsHeadline = () => import('../../components/theme/atoms/Headline.vue' /* webpackChunkName: "components/theme-atoms-headline" */).then(c => wrapFunctional(c.default || c))
export const ThemeAtomsIcon = () => import('../../components/theme/atoms/Icon.vue' /* webpackChunkName: "components/theme-atoms-icon" */).then(c => wrapFunctional(c.default || c))
export const ThemeAtomsText = () => import('../../components/theme/atoms/Text.vue' /* webpackChunkName: "components/theme-atoms-text" */).then(c => wrapFunctional(c.default || c))
export const ThemeOrganismsFooter = () => import('../../components/theme/organisms/Footer.vue' /* webpackChunkName: "components/theme-organisms-footer" */).then(c => wrapFunctional(c.default || c))
export const ThemeOrganismsNavigation = () => import('../../components/theme/organisms/Navigation.vue' /* webpackChunkName: "components/theme-organisms-navigation" */).then(c => wrapFunctional(c.default || c))
export const ThemeTemplatesMainframe = () => import('../../components/theme/templates/Mainframe.vue' /* webpackChunkName: "components/theme-templates-mainframe" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
