//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from 'assets/images/logo.svg?inline'
import ArrowDown from 'assets/images/arrow-down.svg?inline'

export default {
  components: {
    Logo,
    ArrowDown,
  },
  head() {
    return {
      bodyAttrs: {
        class: [this.$style[this.active ? 'menu-active' : 'menu-inactive']],
      },
    }
  },
  computed: {
    home() {
      try {
        return this.$store.state.typo3.initial.navigation[0].link
      } catch (error) {
        return {}
      }
    },
    mainMenuItems() {
      try {
        return this.$store.state.typo3.initial.navigation[0].children
      } catch (error) {
        return []
      }
    },
    subMenuItemsCol1() {
      try {
        return this.$store.state.typo3.initial.submenus.column1
      } catch (error) {
        return []
      }
    },
    subMenuItemsCol2() {
      try {
        return this.$store.state.typo3.initial.submenus.column2
      } catch (error) {
        return []
      }
    },
    subMenuItemsCol3() {
      try {
        return this.$store.state.typo3.initial.submenus.column3
      } catch (error) {
        return []
      }
    },
    subMenuItemsCol4() {
      try {
        return this.$store.state.typo3.initial.submenus.column4
      } catch (error) {
        return []
      }
    },
    title() {
      try {
        const { navigation, title } = this.$store.state.typo3.page.page
        const { slug, navTitle } = navigation
        return slug !== '/' ? (navTitle !== '' ? navTitle : title) : null
      } catch (error) {
        return ''
      }
    },
    active() {
      return this.$store.state.menuStatus
    },
  },
  watch: {
    '$route.path'(newPath, oldPath) {
      if (oldPath !== newPath) this.$store.commit('closeMenu')
    },
  },
}
