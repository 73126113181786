import Vue from 'vue'
import VueMatchMedia from '@webqam/vue-match-media'
import tailwind from '~tailwind.config'

const breakpoints = {
  sm: {
    breakpoint: tailwind.theme.screens.sm,
    defaultValue: true,
  },
  md: {
    breakpoint: tailwind.theme.screens.md,
    defaultValue: false,
  },
  lg: {
    breakpoint: tailwind.theme.screens.lg,
    defaultValue: false,
  },
  xl: {
    breakpoint: tailwind.theme.screens.xl,
    defaultValue: false,
  },
  '2xl': {
     breakpoint: tailwind.theme.screens['2xl'],
    defaultValue: false,
  },
}

Vue.use(VueMatchMedia, { breakpoints })
