//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    social() {
      try {
        return this.$store.state.typo3.page.page.contact[0].socialMediaProfile
      } catch (error) {
        return []
      }
    },
    address() {
      try {
        return this.$store.state.typo3.page.page.contact[0].address
      } catch (error) {
        return []
      }
    },
    email() {
      try {
        return this.$store.state.typo3.page.page.contact[0].email
      } catch (error) {
        return []
      }
    },
    phone() {
      try {
        return this.$store.state.typo3.page.page.contact[0].phone
      } catch (error) {
        return []
      }
    },
    subMenuItemsCol2() {
      try {
        return this.$store.state.typo3.initial.submenus.column2
      } catch (error) {
        return []
      }
    },
    subMenuItemsCol3() {
      try {
        return this.$store.state.typo3.initial.submenus.column3
      } catch (error) {
        return []
      }
    },
    subMenuItemsCol4() {
      try {
        return this.$store.state.typo3.initial.submenus.column4
      } catch (error) {
        return []
      }
    },
  },
}
