
import Vue from 'vue'

export default {
  name: 'NavLink',
  functional: true,
  extends: Vue.component('RouterLink'),
  props: {
    matchIcon: {
      type: Boolean,
      default: false,
    },
  },
  render(createElement, ctx) {
    const prepareLink = (url) => {
      if (
        typeof url === 'string' &&
        url.match(/^(http(s)?|ftp|mailto|tel|sms):/)
      ) {
        return {
          tag: 'a',
          data: {
            attrs: {
              href: url,
              target: '_blank',
              rel: 'noopener noreferrer',
            },
          },
          props: {},
        }
      }

      return {
        tag: 'nuxt-link',
        data: {},
        props: {
          to: url || '/',
        },
      }
    }
    const { matchIcon, tag, to, ...props } = ctx.props
    const url = prepareLink(to)

    let children = ctx.children ? ctx.children : []

    if (matchIcon && to.match(/^(mailto|tel|sms):/i)) {
      children = [
        createElement('ThemeAtomsIcon', {
          props: {
            name: to.match(/^(mailto|tel|sms):/i)[1].toLowerCase(),
          },
        }),
        ...children,
      ]
    } else if (matchIcon && to.match(/^http.*(instagram|facebook).*\./i)) {
      children = [
        createElement('ThemeAtomsIcon', {
          props: {
            name: to.match(/^http.*(instagram|facebook).*\./i)[1].toLowerCase(),
          },
        }),
        ...children,
      ]
    } else if (matchIcon && to.match(/^https?/i)) {
      children = [
        createElement('b', children),
        createElement('ThemeAtomsIcon', {
          props: {
            name: 'external',
          },
        }),
      ]
    }

    return createElement(
      url.tag,
      {
        ...ctx.data,
        ...url.data,
        props: {
          props,
          ...url.props,
        },
      },
      children
    )
  },
}
