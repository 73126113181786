var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.navigation},[_c('div',{class:_vm.$style.navbar},[_c('div',{class:_vm.$style.left},[_c('NavLink',{attrs:{"to":_vm.home}},[_c('span',{staticClass:"hidden"},[_vm._v("Home")]),_vm._v(" "),_c('Logo',{class:_vm.$style.logo})],1)],1),_vm._v(" "),_c('div',{class:_vm.$style.right,on:{"click":function($event){return _vm.$store.commit('triggerMenu')}}},[(_vm.title)?_c('h1',{class:_vm.$style.headline},[_vm._v(_vm._s(_vm.title))]):_vm._e()]),_vm._v(" "),_c('button',{class:[_vm.$style.button, ( _obj = {}, _obj[_vm.$style.active] = _vm.active, _obj )],on:{"click":function($event){return _vm.$store.commit('triggerMenu')}}},[_c('ArrowDown',{class:_vm.$style.arrow}),_vm._v(" Menü\n    ")],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],class:[_vm.$style.overlay]},[_c('div',{class:_vm.$style.left,on:{"click":function($event){return _vm.$store.commit('closeMenu')}}}),_vm._v(" "),_c('div',{class:_vm.$style.right},[_c('TYPO3MenuAccordion',{attrs:{"children":_vm.mainMenuItems},scopedSlots:_vm._u([{key:"item-l1",fn:function(ref){
var item = ref.item;
var click = ref.click;
return [(item.children)?_c('span',{class:_vm.$style['nav-item-level-1'],on:{"click":click}},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")]):_c('NavLink',{class:_vm.$style['nav-item-level-1'],attrs:{"to":item.link}},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")])]}},{key:"item-l2",fn:function(ref){
var item = ref.item;
return [_c('NavLink',{class:_vm.$style['nav-item-level-2'],attrs:{"match-icon":"true","to":item.link}},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")])]}}])}),_vm._v(" "),_c('TYPO3MenuAccordion',{attrs:{"children":_vm.subMenuItemsCol1.concat( _vm.subMenuItemsCol2)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('NavLink',{class:_vm.$style['nav-item-level-2'],attrs:{"to":item.link}},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")])]}}])}),_vm._v(" "),_c('TYPO3MenuAccordion',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$matchMedia.lg),expression:"!$matchMedia.lg"}],attrs:{"children":_vm.subMenuItemsCol3.concat( _vm.subMenuItemsCol4)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('NavLink',{class:_vm.$style['nav-item-level-2'],attrs:{"to":item.link}},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }