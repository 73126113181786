//
//
//
//
//
//
//

import baseBackendLayout from '@/node_modules/nuxt-typo3/lib/templates/layouts/backend/mixins/baseBe'

export default {
  extends: baseBackendLayout,
}
